import React, { Component } from 'react';
import { Button } from '@cargo/ui-kit/button/button';
import MoreIcon from "../../svg-icons/more.svg";

export const MoreButton = (props) => {

	const {children, className, labelIcon,...otherProps} = props;

	return (
		<Button
			label={labelIcon ? labelIcon : <MoreIcon />}
			className={`more-button${className ? ' '+className : ''} `}
			{...otherProps}
		>
			{children}
		</Button>
	);

};