import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { bindActionCreators } from 'redux';

import { Button } from "@cargo/ui-kit/button/button";
import { AlertContext } from "@cargo/ui-kit/alert/alert";
import { MenuContext } from "@cargo/common/context-menu";


import { helpers } from "@cargo/common";

import _ from 'lodash';

const uiWindowComponentList = {};

let uiWindowResizeObserver = null;

if(!helpers.isServer) {

	uiWindowResizeObserver = new ResizeObserver(entries => {

		entries.forEach(entry => {

			const uiWindow = uiWindowComponentList[entry.target.__UIwindowId];

			if ( !uiWindow ){
				return;
			}

			uiWindow.onResize(entry.contentRect)

		});
		
	});
}

class UIWindowClass extends Component {

	constructor(props) {
		super(props);

		this.UIWindowId = _.uniqueId();

		this.state = {
			// drag behavior
			drag: {
				isDragging: false,
				isDragged: false,
				affinity: {
					top: false,
					bottom: false,
					right: false,
					left: false
				},
				x: 0,
				y: 0
			},

			// element position
			position: {
				x: 0,
				y: 0
			},
			// button position
			buttonPos: {
				x: null,
				y: null,
				width: null,
				height: null
			},
			// initial position and width of button
			initialButtonPos: {
				x: null,
				width: null,
			},
			// component HxW
			el: {
				width: 0,
				height: 0
			},
			// autoHeight: this.props.autoHeight ? true : true, // allow window to determine height based on content
			fillHeight: this.props.fillHeight ? true : false, // force window to maximum allowed height
			// set max height
			maxHeight: null,
			maxInnerHeight: null,
			// Determines where solid border :after element lives
			borderSide: _.get(this.props, 'borderSide') ? _.get(this.props, 'borderSide').toString() : null,
			// which corners are rounded
			borderRadius: _.get(this.props, 'borderRadius') ? _.get(this.props, 'borderRadius').toString() : null,
			visibility: 'hidden', // In use specifically with "waitForHeightBeforeRender" prop.
		}

		// type:: string -- popover / pane, describes window behavior
		// positionType:: string -- corresponts to CSS class for specific position, if not set, window will set it's own position based on button pos.
		// borderRadius:: string -- corresponds to CSS class for border radius corners
		// autoHeight:: boolean -- allow window to determine height based on content
		// fillHeight:: boolean -- force window to maximum size
		// buttonPos:: flattened coordinates of the button, may or may not be used according to arguments set above
		// tabbed:: boolean -- does the window have individual tabs?

		this.uiWindowRef = React.createRef();
		// Lets us know how the window is opened for setting borders.
		// Not a part of state b/c it does not directly affect the component render
		// & b/c setState is asynch and might not update in time for calculations.
		this.vertical = null;
		this.horizontal = null;
		this.positionType = this.props.positionType;

	}

	forceWindowVisibility = () => {
		let accManager = document.querySelector('[window-name="account-manager"]');
		if( accManager ){
			let visibility = window.getComputedStyle(accManager).visibility;
			if( visibility === 'hidden' ){
				this.setState({visibility: 'visible'})
			}

			setTimeout(()=>{
				if (typeof this.props.openCallback === 'function') {
					this.props.openCallback()
				}
			}, 100)

		}

	}

	componentDidMount() {
		// Resize observer handles ui Window width and height setting
		let uiWindow = this.uiWindowRef.current;

		uiWindow.__UIwindowId = this.UIWindowId;
		uiWindowComponentList[this.UIWindowId] = this;
		uiWindowResizeObserver.observe(uiWindow)

		window.addEventListener('forceWindowVisibility',  this.forceWindowVisibility, false);

		if (this.props.buttonPos && !this.props.positionType && this.props.type === 'popover'){

			this.setState({
				buttonPos: {
					x: this.props.buttonPos.x,
					y: this.props.buttonPos.y,
					width: this.props.buttonPos.width,
					height: this.props.buttonPos.height
				},
				initialButtonPos: {
					x: this.props.buttonPos.x,
					y: this.props.buttonPos.y,
					width: this.props.buttonPos.width,
					height: this.props.buttonPos.height
				},
			}, ()=> {
				this.setUIWindowPosition()
			})
		}

		if( this.props.contextMenu === true ){
			this.setState({
				className: 'measure',
				buttonPos: {
					x: this.props.buttonPos.x,
					y: this.props.buttonPos.y,
					width: this.props.buttonPos.width,
					height: this.props.buttonPos.height
				},
				initialButtonPos: {
					x: this.props.buttonPos.x,
					y: this.props.buttonPos.y,
					width: this.props.buttonPos.width,
					height: this.props.buttonPos.height
				},
			}, ()=> {
				if( this.props?.mousePos?.x ){
					this.setContextMenuPositionMouse()
				} else {
					this.setContextMenuPositionButton()
				}
			})
		}

		if( this.props.type === 'pane' ){
			this.setState({
				borderSide:  'top'
			});
		}

	}

	closeButton = () => {
		return(
			<div className="close close-uiWindow">
			{/* <AlertContext.Consumer> */}
			{/* 	{(Alert) => ( */}
					<Button
						onMouseDown={(e)=>{ 
							this.closeWindow();
							if (typeof this.props.closeButtonCallback === 'function') {
								this.props.closeButtonCallback();
							}
						}}
						label={
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
								<circle opacity="var(--ui-close-circle-opacity)" cx="10" cy="10" r="10" fill="var(--ui-color-flat-reverse)"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M10.0002 11.0607L14.3099 15.3703L15.3705 14.3096L11.0609 10L15.3705 5.69036L14.3099 4.6297L10.0002 8.93934L5.69054 4.62964L4.62988 5.6903L8.93958 10L4.62988 14.3097L5.69054 15.3704L10.0002 11.0607Z" fill="var(--ui-color-flat)"/>
							</svg>
						}
					/>
				{/* )} */}
			{/* </AlertContext.Consumer> */}
			</div>
		)
	}

	componentWillUnmount(){
		if ( !helpers.isServer){
			uiWindowResizeObserver.unobserve(this.uiWindowRef.current)
			delete uiWindowComponentList[this.UIWindowId];
		}

		if (typeof this.props.closeCallback === 'function') {
			this.props.closeCallback(this.props.lastClickCoordinates)
		}

		window.removeEventListener('forceWindowVisibility',  this.forceWindowVisibility, false);

		// if (typeof this.props.closeButtonCallback === 'function' && this.props.closeButton === true) {
		// 	this.props.closeButtonCallback(this.props.lastClickCoordinates)
		// }
	}

	componentDidUpdate(prevProps, prevState){
		if (
			this.props.windowSize.width != prevProps.windowSize.width ||
			this.props.windowSize.height != prevProps.windowSize.height
		) {
			this.onWindowResize(prevProps.windowSize);
		}

		if(    this.props.waitForHeightBeforeRender 
			&& this.props.minimumRenderHeight 
			&& this.uiWindowRef?.current?.offsetHeight >= this.props.minimumRenderHeight
			&& this.state.visibility === 'hidden'
		){
			this.setState({visibility: 'visible'})
		}

	}

	setUIWindowPosition() {

		const 	clientWidth 	= this.props.windowSize.width,
				clientHeight 	= this.props.windowSize.height;

		let uiWindow       = this.uiWindowRef.current,
			uiWindowWidth  = uiWindow.offsetWidth,
		    uiWindowHeight = uiWindow.offsetHeight,
		    buttonPos      = this.state.buttonPos,
		    buttonX        = buttonPos.x,
		    buttonY        = buttonPos.y,
		    offsetX        = 1,
		    offsetY        = 1,
		    x              = null,
		    y              = null,
		    rowHeight      = Math.min(buttonPos.height, buttonPos.width);

		    // 1. UIWindow height defaults to 30px before filled with content
		    // 2. ButtonPos is based off the top left corner of the button;
		    // 	  this means all top bar elements are buttonY == 0

		    // Determine how/where we will open the uiWindow
		    // Open left if uiWindow width + button width won't exit viewport boundary.
		    if( uiWindowWidth + buttonX + rowHeight >= clientWidth ){
		    	// UIWindow opens from the right and expands left across the button.

		    	// Set horizontal position
		    	x = buttonX - uiWindowWidth;
		    	x = x + buttonPos.width

		    	// Adjust if the uiWindow overlays the right bar.
		    	if( x + uiWindowWidth >= clientWidth ){
		    		x = ( x - rowHeight )
		    	}
		    	this.horizontal = 'left'
		    } else {
		    	// UIWindow opens from the left and expands right across the button.
		    	x = buttonX;
		    	this.horizontal = 'right'
		    }

		    if( uiWindowHeight > buttonY || uiWindowHeight == 0 && buttonY == 0 ){
		    	// if top right corner of button is positioned less than 30px from viewport top
		    	y = buttonY + rowHeight;
		    	this.vertical = 'below'
		    } else {
		    	// UIWindow opens starting at the top of the button
		    	y = buttonY - offsetY
		    	if( y < rowHeight ){
		    		y = ( buttonY + rowHeight );
		    	}
		    	this.vertical = 'above'
		    }

		    this.setState({
		    	position: {x: x, y: y},
		    	rowHeight: rowHeight
		    });
	}

	setContextMenuPositionButton(){
		setTimeout( () => {
			const 	clientWidth 	= this.props.windowSize.width,
					clientHeight 	= this.props.windowSize.height;
					
			let uiWindowWidth  = this.uiWindowRef?.current?.offsetWidth,
			    uiWindowHeight = this.uiWindowRef?.current?.offsetHeight,
			    buttonPos      = this.state.buttonPos,
			    buttonX        = buttonPos.x,
			    buttonY        = buttonPos.y,
			    offsetX        = 0, //10
			    offsetY        = 0, //15
			    x              = null,
			    y              = null,
			    rowHeight      = Math.min(buttonPos.height, buttonPos.width),
			    tolerance 	   = 25;

			    x = buttonX + offsetX
			    y = buttonY + offsetY

			    if( x + uiWindowWidth > clientWidth - tolerance ){
			        // Open Left
			        x = x - uiWindowWidth + this.props.triggerSize.width; 
			    }

			    if( uiWindowHeight + y > clientHeight - tolerance ){
			        // Open above
			        y = y - uiWindowHeight;
			    }

		    this.setState({
		    	className: '',
		    	position: {x:x, y: y},
		    })
		}, 50 )	 
	}

	setContextMenuPositionMouse(){
		setTimeout( () => {
			const 	clientWidth 	= this.props.windowSize.width,
					clientHeight 	= this.props.windowSize.height;
					
			let uiWindowWidth  = this.uiWindowRef?.current?.offsetWidth,
			    uiWindowHeight = this.uiWindowRef?.current?.offsetHeight,
			    mouseX         = this.props?.mousePos?.x,
			    mouseY         = this.props?.mousePos?.y,
			    offsetX        = 0, //10
			    offsetY        = 0, //15
			    x              = null,
			    y              = null,
			    tolerance 	   = 25;

			    x = mouseX + offsetX
			    y = mouseY + offsetY

			    if( x + uiWindowWidth > clientWidth - tolerance ){
			        // Open Left
			        x = x - uiWindowWidth; 
			    }

			    if( uiWindowHeight + y > clientHeight - tolerance ){
			        // Open above
			        y = y - uiWindowHeight;
			    }

		    this.setState({
		    	className: '',
		    	position: {x:x, y: y},
		    })
		}, 50)	
	}

	onResize = (contentRect) =>{

		let xDelta = 0;
		let yDelta = 0;

		let el = {
			width: contentRect.width,
			height: contentRect.height
		}

		let clampedPosition = {...this.state.position}

		if ( this.state.drag.isDragged && !this.state.drag.isDragging){

			clampedPosition = this.getClampedWindowPosition(undefined, el).position;

		}

		this.setState({
			el: el,
			position: clampedPosition
		})
	}

	render() {

		let outerStyles = {}
		let clickoutStyles = {}

		if ( !this.positionType ){
			outerStyles.top =	this.state.position.y ? this.state.position.y : '';
			outerStyles.left =	this.state.position.x ? this.state.position.x : ''; 
		}

		if ( this.state.drag.isDragged){
			outerStyles.transform = `translate(${this.state.drag.x}px, ${this.state.drag.y}px)`;
			outerStyles.top =	this.state.position.y+'px' 
			outerStyles.left =	this.state.position.x+'px'
		}

		outerStyles.zIndex = this.props.homepageState.activeWindow === this.props.windowName ? 290 : 291;

		// Strip transform style after dragging is complete.  
		if( 
			( this.state.drag.x == 0 || !this.state.drag.x ) &&
			( this.state.drag.y == 0 || !this.state.drag.y ) 
		){
			outerStyles.transform = null;
		}


		if( this.props.waitForHeightBeforeRender && this.props.minimumRenderHeight ){
			outerStyles.visibility = this.state.visibility;
			clickoutStyles.visibility = this.state.visibility;
		}

		if( this.props.windowName !== 'account-manager' && !this.props.waitForHeightBeforeRender ){
			outerStyles.visibility = 'visible';
		}

		if( this.props.windowName === 'account-manager' && this.state.visibility === 'visible' ){
			outerStyles.visibility = 'visible';
		}
		// this.state.borderSide <-- temp value for connecting window to bar.

		return (
			<>
				<div
					className   	= {`uiWindow${this.props.className ? (" "+this.props.className) : ""}${this.props.extraClass ? (" "+this.props.extraClass) : ""}${this.state.className ? (" "+this.state.className) : ""}${this.state.drag.isDragging ? ' dragging' : ''}${(this.props.clickoutLayer || this.props.preventClickout) && !this.props.preventHoisting ? ' hoisted' : ''}${this.props.legacyWindow ? " foundation-css" : ""}`}
					window-name 	= {`${this.props.windowName ? this.props.windowName : ""}`}
					style       	= { outerStyles }
					type        	= { this.props.type }
					tabbed      	= { _.get(this.props, 'tabbed') ? _.get(this.props, 'tabbed').toString() : null }
					position    	= {this.positionType       ? this.positionType.toString()       : undefined }
					border-type 	= {this.state.borderRadius ? this.state.borderRadius.toString() : undefined }
					border-side 	= {this.state.borderSide   ? this.state.borderSide.toString()   : undefined }
					fill-height 	= {this.props.fillHeight   ? this.props.fillHeight.toString()   : undefined }
					ref         	= { this.uiWindowRef }
					onPointerDown	= {this.onPointerDown}
				>
					<MenuContext.Consumer>
						{(Menu) => 
							<AlertContext.Consumer>
								{(Alert) => 
									<div className="uiWindow-content">
										<div className="uiWindow-inner">
											{ this.props.closeButton === true ? ( this.closeButton() ) : ( null )}
											{React.cloneElement(this.props.children, { 
												uiWindowProps: this.props,
												Menu: Menu,
												Alert: Alert,
		                                	}) }
										</div>
									</div>
								}
							</AlertContext.Consumer>
						}
					</MenuContext.Consumer>
				</div>

				{ this.props.clickoutLayer || this.props.preventClickout ?

					<div 
						onClick={
							e=> {
								e.persist();
								this.clickout(e);
							}
						}
						className={`clickout-layer${this.props.type === "focus-modal" || this.props.clickoutLayerDim ? ' dim' : ''}`}
						style={clickoutStyles}
						id={`clickout-layer-${this.props.windowName}`}
					></div>

				: null }
				</>
		)
	}

	clickout = (e) => {
		// NOTE: this is a temporary method
		// the clickout layer is handled in the window-ui-layer by default
		if (this.props.preventClickout) {
			e.preventDefault();
			// e.stopImmediatePropagation();
			return;	
		} 

		if (this.props.clickoutLayer) {
			this.closeWindow()	
		}
	}

	closeWindow = () => {
		this.props.removeUIWindow(uiWindow => {
			return uiWindow.props.windowName === this.props.windowName
		});
	}

	handleC2SavableViewRemoval = () => {

		const showRemoteAlert = new CustomEvent('open-remote-alert', {
		   detail: {
		       message: "Save changes?",
		       modalType: 'confirm',
		       onConfirmFunction: this.confirmC2SaveFunction,
		       onDenyFunction: this.denyC2SaveFunction
		   }
		});

		document.dispatchEvent( showRemoteAlert );

	}

	confirmC2SaveFunction = () => {
		Cargo.AccountManager.save().then(()=> {

			this.props.removeUIWindow(uiWindow => {
				return uiWindow.props.windowName === this.props.windowName
			});

		}).fail(() => {
			console.warn("Save failed.")
		})
	}

	denyC2SaveFunction = () => {

		Cargo.AccountManager.saveButton.trigger('save_disable');

		this.props.removeUIWindow(uiWindow => {
			return uiWindow.props.windowName === this.props.windowName
		});

		window.location.reload();

	}

	// all drag events
	onPointerDown = (e) =>{

		if ( e.button != 0 || _.get(this.props, 'disableDragging') ){
			return;
		}

		// u.cargo dragging is OPT IN rather than admin opt out
		if( _.get(this.props, 'enableDragging') !== true ){
			return
		}

		if (this.props.type !== 'popover') return;

		// drag blacklist
		if (
			e.target.closest('.text-block') ||
			e.target.tagName == 'IMG' ||
			e.target.closest('button') ||
			e.target.closest('input') ||
			e.target.closest('a') ||
			e.target.closest('window-tab[chosen="false"]') ||
			// temporary page list handle
			e.target.closest('.drag-item')
		) {
			return;
		}

		// drag whitelist
		if ( e.target.closest('.ui-group') &&
			!e.target.classList.contains('more-actions') &&
			!e.target.classList.contains('uiWindow-spacer') &&
			!e.target.classList.contains('help')
		) {
			return;
		}

		// No dragging
		// return 
		// if the position is set by CSS, then we need to get its rect before initing the drag
		if (this.positionType){
			const rect = this.uiWindowRef.current.getBoundingClientRect();
			this.setState({
				position: {
					x: rect.left,
					y: rect.top,
				}
			})
		}

		this.startPointerX = this.currentPointerX = e.clientX;
		this.startPointerY = this.currentPointerY = e.clientY;

		this.pointerMoveTick = requestAnimationFrame(this.updateDragPosition)

		this.props.updateHomepageState({
			activeWindow: this.props.windowName
		});

		window.addEventListener('pointermove', this.onPointerMove);
		window.addEventListener('pointerup', this.onPointerUp);
		window.addEventListener('pointercancel', this.onPointerUp)

	}

	onPointerMove = (e) => {
		e.preventDefault();
		this.currentPointerX = e.clientX;
		this.currentPointerY = e.clientY;
	}

	updateDragPosition = (e)=> {

		let xDelta = this.currentPointerX - this.startPointerX;
		let yDelta = this.currentPointerY - this.startPointerY;

		let el = this.state.el;
		// easiest way to stop click propagation and iframe from swallowing events
		// for now
		// if ( !this.state.isDragging ){
		// 	document.body.style.pointerEvents = 'none';
		// }

		const position = {...this.state.position}
		const clampedPosition = this.getClampedWindowPosition({
			x: position.x+xDelta,
			y: position.y+yDelta
		})

		this.setState({
			drag: {
				x: clampedPosition.position.x - position.x,
				y: clampedPosition.position.y - position.y,
				isDragged: true,
				isDragging: true,
				affinity: clampedPosition.affinity
			}
		}, ()=>{
			this.pointerMoveTick = requestAnimationFrame(this.updateDragPosition)			
		})
	}

	onPointerUp = (e) => {

		cancelAnimationFrame(this.pointerMoveTick);

		this.startPointerX = this.currentPointerX = 0;
		this.startPointerY = this.currentPointerY = 0;

		// drag = transform, position = top/left
		// after release, combine the two so the element has no transforms

		let drag = {...this.state.drag};
		let position = {...this.state.position};

		this.setState({
			drag: {
				x: 0,
				y: 0,
				isDragged: true,
				isDragging: false,
				affinity: drag.affinity
			},
			position: {
				x: Math.round(drag.x+position.x),
				y: Math.round(drag.y+position.y),
			}
		})

		// reset pointer events
		document.body.style.pointerEvents = '';

		window.removeEventListener('pointermove', this.onPointerMove);
		window.removeEventListener('pointerup', this.onPointerUp)
		window.removeEventListener('pointercancel', this.onPointerUp)		
	}

	getClampedWindowPosition = (attemptedPosition = this.state.position, el = this.state.el) =>{

		const prevAffinity = {...this.state.drag.affinity}
		
		// empty space enforced around the window
		const margin = -400;

		// the amount of the window that must not overlap the borders
		// macOS behavior would be something like {top: el.height, left: 2, right: 2, bottom: 2}
		const minUnderlap = {
			top: el.height + margin,
			bottom: el.height + margin,
			left: el.width + margin,
			right: el.width + margin,
		}	

		console.log("el", el.height, margin )

		//buttonPos.width/height is not reliable for all ui windows. going to hardcode limits instead... for now
		const rowSize = {
			height: 5,
			width: 5
		}

		// when resizing, we either maintain position in window or clamp to the nearest edge(s)
		// these zones tell us when to use that behavior
		// if two opposing zones are active (top and bottom), keep with previous one
		const zones = {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0
		}

		// const rightEdge = this.props.windowSize.width+-rowSize.width+-minUnderlap.right;
		const rightEdge = this.props.windowSize.width+-minUnderlap.right
		const leftEdge = minUnderlap.left;

		// const topEdge = minUnderlap.top+rowSize.height;
		const topEdge = minUnderlap.top;
		const bottomEdge = this.props.windowSize.height+-minUnderlap.bottom;

		let position = {...attemptedPosition};

		let affinity = {
			top:  		Math.max( 0, (topEdge-minUnderlap.top) - (attemptedPosition.y + -zones.top) ),
			bottom: 	Math.max( 0, (attemptedPosition.y+el.height + zones.bottom)  -  (bottomEdge+minUnderlap.bottom) ),
			right: 		Math.max( 0, (attemptedPosition.x+el.width + zones.right) - (rightEdge+minUnderlap.right) ),
			left: 		Math.max( 0, (leftEdge-minUnderlap.left) - (attemptedPosition.x + -zones.left) )
		}

		// resolve affinity conflicts by comparing
		if ( affinity.top > 0 && affinity.bottom > 0 ){
			if ( affinity.top >= affinity.bottom ){
				affinity.bottom = 0;
			} else {
				affinity.top = 0;
			}
		}

		if ( affinity.right > 0 && affinity.left > 0 ){
			if ( affinity.right >= affinity.left ){
				affinity.left = 0;
			} else {
				affinity.right = 0;
			}
		}

		// clamp movement to edge
		if ( attemptedPosition.y >= bottomEdge ){
			position.y = bottomEdge
		}

		if (attemptedPosition.y + el.height <= topEdge){
			position.y = topEdge + -el.height
		}

		if ( attemptedPosition.x >= rightEdge ){
			position.x = rightEdge
		}

		if (attemptedPosition.x + el.width <= leftEdge){
			position.x = leftEdge + -el.width;
		}

		return {position, affinity}

	}

	onWindowResize = (prevWindowSize)=>{

		// items that havent been dragged already know their place
		if ( !this.state.drag.isDragged){
			return;
		}

		const diffWidth = this.props.windowSize.width - prevWindowSize.width;
		const diffHeight = this.props.windowSize.height - prevWindowSize.height;

		let {x, y} = this.state.position;
		let el = {...this.state.el};

		if ( this.state.drag.affinity.top) {

		} else if ( this.state.drag.affinity.bottom) {

			y = y+diffHeight

		} else {

			let prevPosYPercentage = (y + el.height*.5)/prevWindowSize.height
			y = prevPosYPercentage * this.props.windowSize.height + -el.height*.5
		}

		if ( this.state.drag.affinity.left) {

		} else if ( this.state.drag.affinity.right) {

			x = x+diffWidth

		} else {

			let prevPosYPercentage = (x + el.width*.5)/prevWindowSize.width
			x = prevPosYPercentage * this.props.windowSize.width + -el.width*.5
		}

		let clampedPosition = this.getClampedWindowPosition({x, y}, undefined).position;

		this.setState({
			position: clampedPosition
		})

	}	

}

function mapReduxStateToProps(state, ownProps) {

	return {
		homepageState: state.homepageState,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		removeUIWindow: actions.removeUIWindow,
		updateUIWindow: actions.updateUIWindow,
		updateHomepageState: actions.updateHomepageState,
	}, dispatch);

}


export const UIWindow = connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(UIWindowClass);

